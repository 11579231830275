// src/components/SugarImpactAnalyzer.tsx
import React, { useState } from 'react';


import TopItemsDisplay from '../components/Products/TopItemsToDisplay';
import HealthierAlternativesModal from '../components/Products/HealthierAlternatives';

const SugarImpactAnalyzer: React.FC = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedItems, setSelectedItems] = useState<string[]>([]);

    const handleAddItem = (item: string) => {
        setSelectedItems([item]);
        setIsModalOpen(true);
    };

    return (
        <div className="min-h-screen bg-blue-50 p-4 font-sans flex flex-col">
            <div className='container mx-auto gap-4 mt-6'>
            <h1 className="text-3xl font-bold text-gray-800 mb-4 text-center">Sugar Impact Analyzer</h1>
            <p className="text-gray-600 mb-8 text-center">Understand the impact of sugar in popular snacks and drinks, compare items, and explore healthier choices.</p>
            <HealthierAlternativesModal
                selectedItems={selectedItems}
                isOpen={isModalOpen}
                onClose={() => {
                    setIsModalOpen(false);
                    setSelectedItems([]);
                }}
            />
            <TopItemsDisplay onAddItem={handleAddItem} selectedItems={selectedItems} />

            </div>
        </div>
    );
};

export default SugarImpactAnalyzer;