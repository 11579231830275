import React, { useState } from 'react';
import api from "../../services/api";
import {Story} from "../../types/Stories";

interface StoryViewProps {
    streak: number;
    story: Story;
    userName: string;
}

const timeAgo = (date: string) => {
    const seconds = Math.floor((new Date().getTime() - new Date(date).getTime()) / 1000);
    const intervals = [
        { label: 'y', seconds: 31536000 },
        { label: 'mo', seconds: 2592000 },
        { label: 'w', seconds: 604800 },
        { label: 'd', seconds: 86400 },
        { label: 'h', seconds: 3600 },
        { label: 'm', seconds: 60 },
        { label: 's', seconds: 1 },
    ];

    for (const interval of intervals) {
        const count = Math.floor(seconds / interval.seconds);
        if (count >= 1) {
            return `${count}${interval.label} ago`;
        }
    }
    return 'just now';
};

const StoryView: React.FC<StoryViewProps> = ({ streak, story, userName }) => {
    const [claps, setClaps] = useState<number>(parseInt(story.likes) || 0);

    const handleClap = () => {
        setClaps(claps + 1);

        api.post(`/stories/${story.id}/like`).then(() => {
            console.log('Clapped!');
        }).catch((err) => {
            console.error(err);
            setClaps(claps - 1);
        });
    };

    const getStreakMessage = () => {
        if (streak === 1) return `${userName} completed their first sugar-free day! 🎉`;
        if (streak < 7) return `${userName} is making great progress with day ${streak} of their sugar-free journey! 💪`;
        if (streak < 30) return `${userName} has been unstoppable for ${streak} sugar-free days! 🚀`;
        return `${userName} has mastered their journey with an impressive ${streak} days sugar-free! 🌟`;
    };

    return (
        <div className="p-6 bg-white shadow-lg rounded-xl border border-gray-200 max-w-lg mx-auto mt-8">
            {/* Header: Achievement Message */}
            <h2 className="font-bold text-indigo-800 mb-4">
                {getStreakMessage()}
            </h2>

            {/* User Story: Centered and Highlighted */}
            <blockquote className="text-center text-gray-800 text-md font-medium italic leading-relaxed mb-4">
                “{story.content}”
            </blockquote>

            {/* Divider Line */}
            <div className="border-t border-gray-300 my-4"></div>

            {/* Footer: Clap Interaction and Date */}
            <div className="flex items-center justify-between">
                <span className="text-sm text-gray-500">Shared {timeAgo(story.created_at)}</span>
                <button
                    onClick={handleClap}
                    className="px-4 py-1.5 bg-indigo-500 text-white font-medium rounded-full shadow hover:bg-indigo-600 transition transform hover:scale-105 focus:outline-none"
                >
                    👏 Clap {claps}
                </button>
            </div>
        </div>
    );
};

export default StoryView;