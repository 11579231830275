// src/components/Feed.tsx
import React, { useEffect, useState } from 'react';
import { Achievement } from '../types/Achievment';
import AchievementCard from '../components/AchievmentCardComponent';
import api from '../services/api';
import ShareBox from "../components/feed/ShareBoxComponent";
import {useAuth} from "../contexts/AuthContext";
import StoryView from "../components/feed/StoryViewComponent";
import {Story} from "../types/Stories";

const Feed: React.FC = () => {
    const [achievements, setAchievements] = useState<Achievement[]>([]);
    const [stories, setStories] = useState<Story[]>([]);

    const [error, setError] = useState<string | null>(null);
    const {user} = useAuth();

    useEffect(() => {
        const fetchAchievements = async () => {
            try {
                const response = await api.get('/achievements');
                setAchievements(response.data?.achievements || []);
            } catch (err) {
                setError((err as Error)?.message || "An error occurred. Please try again.");
            }
        };

        const fetchStories = async () => {
            try {
                const response = await api.get('/stories');
                setStories(response.data || []);
            } catch (err) {
                setError((err as Error)?.message || "An error occurred. Please try again.");
            }
        };

        fetchAchievements();
        fetchStories();
    }, []);

    const handlePost = async (description: string) => {
        try {
            const response = await api.post('/stories', { content: description });
            setAchievements([response.data.achievement, ...achievements]);
        } catch (err) {
            setError((err as Error)?.message || "An error occurred. Please try again.");
        }
    }

    if (error) {
        return <p className="text-red-500">Error: {error}</p>;
    }

    return (
        <div className="min-h-screen bg-blue-50 p-6 flex flex-col items-center font-sans text-gray-800">
            <ShareBox onPost={handlePost} streak={parseInt(user?.streak || '0')} />

            {stories && stories.map((story) => (
                    <div key={story.id} className="space-y-4 w-full">
                        <StoryView key={story.id} streak={parseInt(user?.streak || '0')} story={story} userName={user?.name || ''} />
                        <div className="text-center text-2xl">🌱</div>
                    </div>
                ))}

            {achievements.length > 0 ? (
                achievements.map((achievement) => (
                    <div className="space-y-4 w-full">
                        <AchievementCard key={achievement.id} achievement={achievement} />
                        <div className="text-center text-2xl">🌱</div>
                    </div>
                ))
            ) : (
                <p className="text-gray-500">No updates yet!</p>
            )}
        </div>
    );
};

export default Feed;
