import React, { useState } from "react";

interface ShareBoxProps {
    streak: number;
    onPost: (content: string) => void;
}

const ShareBox: React.FC<ShareBoxProps> = ({ streak, onPost }) => {
    const [content, setContent] = useState<string>("");

    const handlePost = () => {
        if (content.trim()) {
            onPost(content);
            setContent("");
        }
    };

    // Personalize message based on streak
    const getPersonalizedMessage = () => {
        if (streak === 1) {
            return "How was your first day without sugar? What did you notice?";
        } else if (streak === 2) {
            return "Two days strong! How are you feeling so far?";
        } else if (streak < 7) {
            return `You're ${streak} days in! What changes have you started to notice?`;
        } else if (streak < 30) {
            return `Amazing work on ${streak} days sugar-free! What keeps you motivated?`;
        } else {
            return `Incredible! ${streak} days without sugar—you're an inspiration. What tips or reflections can you share?`;
        }
    };

    return (
        <div className="relative p-8 bg-gradient-to-br from-indigo-50 via-white to-indigo-100 shadow-lg rounded-xl border border-gray-200 max-w-3xl mx-auto">
            {/* Decorative Glow */}
            <div className="absolute inset-0 blur-3xl bg-gradient-to-r from-purple-200 to-indigo-300 opacity-40 rounded-xl pointer-events-none"></div>

            <div className="relative z-10">
                {/* Header */}
                <div className="text-center mb-6">
                    <h2 className="text-2xl font-bold text-gray-800 leading-tight">
                        🌟 Share Your Journey, Inspire Others 🌟
                    </h2>
                    <p className="text-sm text-gray-600 mt-2">{getPersonalizedMessage()}</p>
                </div>

                {/* Input Box */}
                <textarea
                    className="w-full h-32 p-4 border rounded-lg bg-white shadow focus:ring-2 focus:ring-indigo-400 focus:outline-none text-gray-800 text-sm placeholder-gray-500 border-gray-300 resize-none transition-all"
                    placeholder="Write your story here... (e.g., 'I overcame cravings today by...')"
                    value={content}
                    onChange={(e) => setContent(e.target.value)}
                />

                {/* Call to Action */}
                <div className="flex justify-end items-center mt-6">
                    <p className="text-xs text-gray-500 italic mr-4">
                        Your story could light someone’s way. ✨
                    </p>
                    <button
                        className={`px-6 py-2 text-sm font-semibold rounded-lg shadow-md transition-all ${
                            content.trim()
                                ? "bg-gradient-to-r from-indigo-600 to-purple-500 text-white hover:from-indigo-500 hover:to-purple-400 focus:ring-2 focus:ring-indigo-300"
                                : "bg-gray-300 text-gray-500 cursor-not-allowed"
                        }`}
                        onClick={handlePost}
                        disabled={!content.trim()}
                    >
                        Share Now
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ShareBox;